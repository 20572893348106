import { useCallback, useEffect, useState } from "react";

import { useMediaScreenContext } from "~/contexts/madia-screen";

import { SCREENS } from "../constants";

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(() => getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}
export const usePick = () => useMediaScreenContext().pick;
export const useMediaScreen = () => {
  const sm = useMediaQuery(
    `(max-width: ${parseInt(SCREENS.md.replace("px", "")) - 1}px)`,
  );
  const md = useMediaQuery(
    `(min-width: ${SCREENS.md}) and (max-width: ${
      parseInt(SCREENS.lg.replace("px", "")) - 1
    }px)`,
  );
  const lg = useMediaQuery(
    `(min-width: ${SCREENS.lg}) and (max-width: ${
      parseInt(SCREENS.xl.replace("px", "")) - 1
    }px)`,
  );
  const xl = useMediaQuery(`(min-width: ${SCREENS.xl})`);

  const pick = useCallback(
    <T,>([_sm, _md, _lg, _xl]: [sm: T, md?: T, lg?: T, xl?: T]):
      | T
      | undefined => {
      if (xl) return _xl ?? _lg ?? _md ?? _sm;
      if (lg) return _lg ?? _md ?? _sm;
      if (md) return _md ?? _sm;
      if (sm) return _sm;
      return _sm;
    },
    [sm, md, lg, xl],
  );
  return {
    is: sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : "xl",
    isMobile: sm,
    isTablet: md,
    isDesktop: lg || xl,

    pick,
  } satisfies {
    is: "sm" | "md" | "lg" | "xl";
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    pick: <T>([sm, md, lg, xl]: [sm: T, md?: T, lg?: T, xl?: T]) =>
      | T
      | undefined;
  };
};
