import { createContext, useContext } from "react";

import { useMediaScreen } from "~/lib/utils/screens";

const MediaScreenContext = createContext<ReturnType<typeof useMediaScreen>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any,
);

export const useMediaScreenContext = () => {
  const context = useContext(MediaScreenContext);
  if (!context) {
    throw new Error("useMediaScreen must be used within a MediaScreenProvider");
  }
  return context;
};

export const MediaScreenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const media = useMediaScreen();
  return (
    <MediaScreenContext.Provider value={media}>
      {children}
    </MediaScreenContext.Provider>
  );
};
